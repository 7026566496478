import React, { Component } from 'react';
import { Jumbotron } from 'reactstrap';
import './Hero.scss';

export default class Hero extends Component {
    render(){
        return (
            <Jumbotron fluid>
            <div className="welcome d-flex align-items-around">
                <strong className="mt-auto">Welcome to Step of Faith Church</strong>
                <strong className="mt-auto ml-auto">Bienvenidos Iglesia Paso de Fe</strong>
            </div>
            </Jumbotron>
          );
    }
}

