import React, { Component } from 'react';
import './Contact.scss';

import Heading from '../heading/Heading'

export default class Contact extends Component {
    render(){
        return (
            <div className="container contact">
                <Heading name="Contact Us"></Heading>
                <a href="mailto:stepoffaith.rg@gmail.com?Subject=Hello%20Pastor" target="_top">
                    Send us an email
                </a>
            </div>
          );
    }
}

