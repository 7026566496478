import React, { Component } from 'react';

import Heading from '../heading/Heading'
import Tithely from '../buttons/Tithely';

export default class About extends Component {
    render(){
        return (
            <div className="container">
                <Tithely />
                <Heading name="About Us"></Heading>
                <p>We are a non-denominational church located in the Tower District, Fresno, California. SOFChurch is a faithfully active ministry since 2008.</p>
                <h3 className="secondary sub-heading">Our Vision</h3>
                <p>It has always been to REACH, RESTORE, REVEAL, and REPRODUCE lives for Jesus Christ</p>
            </div>
          );
    }
}

