import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './Location.scss';

import Heading from '../heading/Heading'

export default class Location extends Component {
    static defaultProps = {
        center: {
          lat: 36.75,
          lng: 119.79
        },
        zoom: 10
      };
    render(){
        let mapURL = "https://www.google.com/maps/place/Step+of+Faith+Church/@36.750631,-119.798209,15z/data=!4m5!3m4!1s0x0:0x434a73a90d5dfe2!8m2!3d36.7506307!4d-119.7982091?hl=en-US"
        return (
            <div className="container location">
                <Heading name="Our Location"></Heading>
                <div className="row d-flex justify-content-center secondary-background">
                    <div className="col-9">
                        <p>1027 East Belmont Ave, Fresno, California</p>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <a href={mapURL} target="_blank" rel="noopener noreferrer"><Button color="info m-2">directions</Button></a>
                    </div>
                </div>
            </div>
          );
    }
}

