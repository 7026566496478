import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import Menu from './elements/menu/Menu';
import Hero from './elements/hero/Hero';
import About from './elements/about/About';
import Leadership from './elements/leadership/Leadership';
import Service from './elements/service/Service';
import Location from './elements/location/Location';
import Contact from './elements/contact/Contact';

import './App.scss';
import Footer from './elements/footer/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
      <Menu />
      <Hero />
      <About />
      <Leadership />
      <Service />
      <Location />
      <Contact />
      <Footer />
      </div>
    );
  }
}

export default App;
