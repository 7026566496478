import React, { Component } from 'react';
import './Tithely.scss';

import Heading from '../heading/Heading'

export default class Tithely extends Component {
    render(){
        return (
            <button className="tithely-give-btn" data-church-id="456067">Give</button>
          );
    }
}

