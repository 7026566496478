import React, { Component } from 'react';
import './Leadership.scss';

import Heading from '../heading/Heading'

import { Card, CardImg, CardBody,
    CardTitle, CardSubtitle } from 'reactstrap';

export default class Leadership extends Component {
    render(){
        let paster = "https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/manny-missy.jpg?alt=media&token=f816c397-4e70-4c26-93de-3bd7f12e7c1a"
        let coPaster = "https://firebasestorage.googleapis.com/v0/b/stepoffaithchurch-6bf25.appspot.com/o/richard-helen.jpg?alt=media&token=ebb7efb0-1238-451d-b3e0-fa8d5069a101"
        return (
            <div className="container">
                <Heading name="Leadership" />

                <div className="row">
                    <div className="col">
                    <Card>
                        <CardImg top width="100%" src={paster} alt="Card image cap" />
                        <CardBody>
                        <CardTitle><h4>Senior Pastors</h4></CardTitle>
                        <CardSubtitle><h6>Manny and Missy Gonzalez</h6></CardSubtitle>
                        </CardBody>
                    </Card>
                    </div>
                    <div className="col">
                    <Card>
                        <CardImg top width="100%" src= {coPaster} alt="Card image cap" />
                        <CardBody>
                        <CardTitle><h4>Co-Pastors</h4></CardTitle>
                        <CardSubtitle><h6>Ricardo and Helen Gonzalez</h6></CardSubtitle>
                        </CardBody>
                    </Card>
                    </div>
                </div>

            </div>
        );
    }
}

