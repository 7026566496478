import React, { Component } from 'react';
import './Service.scss';

import Heading from '../heading/Heading'

export default class Service extends Component {
    render(){
        return (
            <div className="container service">
                <Heading name="Our Services"></Heading>
                <h3 className="secondary sub-heading mt-0">Sunday</h3>
                <div className="row d-flex justify-content-center secondary-background">
                    <div className="col-9">
                        <p>Morning Service</p>
                    </div>
                    <div className="col-3">
                        <p>11am</p>
                    </div>
                </div>
                <h3 className="secondary sub-heading">Wednesday</h3>
                <div className="row d-flex justify-content-center secondary-background">
                    <div className="col-9">
                        <p>Evening Service</p>
                    </div>
                    <div className="col-3">
                        <p>7pm</p>
                    </div>
                </div>
                <h3 className="secondary sub-heading">Connect &amp; Grow Thurdays</h3>
                <div className="row d-flex justify-content-center secondary-background">
                    <div className="col-9">
                        <p>Mens &amp; Womens Fellowship</p>
                    </div>
                    <div className="col-3">
                        <p>6pm</p>
                    </div>
                </div>
                <h3 className="secondary sub-heading">Friday</h3>
                <div className="row d-flex justify-content-center secondary-background">
                    <div className="col-9">
                        <p>Evening Prayer</p>
                    </div>
                    <div className="col-3">
                        <p>7pm</p>
                    </div>
                </div>
            </div>
          );
    }
}

