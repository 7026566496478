import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import './Menu.scss';

export default class Menu extends Component {
    render(){
        return (
            <Nav className="menu">
              <h1>Step of Faith Church</h1>
            </Nav>
          );
    }
}

